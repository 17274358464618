

const TokenKey = 'Admin-Token'

export function getToken() {

  return sessionStorage.getItem(TokenKey);
  //return sessionStorage.getItem(TokenKey);
}

export function setToken(token) {
  return sessionStorage.setItem(TokenKey,token);
  //return sessionStorage.setItem(TokenKey, token)
}

export function removeToken() {
  //localStorage.removeItem(TokenKey)
  sessionStorage.removeItem(TokenKey)

}
export function getData(key) {
  return sessionStorage.getItem(key);
}

export function setData(key,data) {
  return sessionStorage.setItem(key,data);
}
export function removeData(key) {
  sessionStorage.removeItem(key)

}
